<template>
  <v-card flat class="transparent mx-auto mb-12" width="960">
    <EditPopupMessages
      v-if="ready"
      :messages.sync="messages"
    />

    <v-row justify="center">
      <v-btn @click="save" dark class="primary">
        update/save
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>

import { roleHandler, accessRightsHandler } from '@/controllers/data-handlers'

export default {
  name: 'CheckAddressPopupMessages',

  components: {
    EditPopupMessages: () => import('@/components/inputs/EditPopupMessages.vue')
  },

  data: () => ({
    accessRights: accessRightsHandler().access[roleHandler()].settings,
    messages: null,
    ready: false
  }),

  methods: {
    getSourceData (data) {
      this.messages = data
      this.ready = true
    },

    callback (response) {
      console.log('Updated\n', response)
    },

    save () {
      this.__updateCheckAddressMessages(this.messages, this.callback)
    }
  },

  beforeMount () {
    this.__getCheckAddressMessages(this.getSourceData)
  }
}
</script>
